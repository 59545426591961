import {
    Modal,
    generateResolver,
    yup,
} from "dyl-components";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Dropdown, Icon, Popup, Segment } from "semantic-ui-react";
import EditQuote from "./EditQuote";
import AddProducts from "./AddProducts";
import ProposalPreview from "./ProposalPreview";
import { useContext, useEffect } from "react";
import { QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";
import { Error500 } from "pages";
import Order from "shared/forms/Order";
import Payment from "shared/Payment";
import { quoteSchema } from "shared/schemas/quote/quoteSchema";
import { orderSchema } from "shared/schemas/order/orderSchema";
import { checkoutSchema } from "shared/schemas/checkout/checkoutSchema";
import { getOrderDefaultValues, getQuoteDefaultValues } from "./helper";

const ModalContent = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id,
}) => {
    const quote = useSelector((state) => state.quote.quote);
    const order = useSelector((state) => state.order.order);

    const { quoteBuilderConfig } = useContext(QuoteBuilderContext);

    const { currentStep, id } = quoteBuilderConfig;

    const defaultValues = (() => {
        if (
            currentStep === "build-quote" ||
            (currentStep === "add-products" && id)
        ) {
            return getQuoteDefaultValues({ quote, account_id });
        }
        if ((currentStep === "order" || currentStep === "edit-order") && id) {
            return getOrderDefaultValues({ order, account_id });
        }
        if (currentStep === "checkout") {
            return {
                invoice_name: "",
            };
        }
        return {
            master_filter: null,
            cart: [],
        };
    })();

    const currentStepSchema = (() => {
        if (currentStep === "build-quote") {
            return quoteSchema;
        }
        if (currentStep === "order" || currentStep === "edit-order") {
            return orderSchema;
        }
        if (currentStep === "checkout") {
            return checkoutSchema;
        }
        return {
            cart: yup
                .array()
                .min(1, "There should be at least one item selected")
                .test("at_least_one_item_selected", "There should be at least one item selected", (cart, context) => {
                    const { master_filter } = context.parent;
                    return cart.filter(item => {
                        return !item.pricing_schedule || !master_filter || item?.schedules?.includes(master_filter);
                    }).length
                }),
        };
    })();

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: generateResolver(currentStepSchema),
    });

    const { trigger, setValue } = methods;

    useEffect(() => {
        if (currentStep === "build-quote" || currentStep.includes("order")) {
            trigger(`cart`);
        }
    }, [currentStep, trigger]);

    useEffect(() => {
        if (currentStep === "build-quote" || currentStep.includes("order")) {
            const items =
                currentStep === "build-quote"
                    ? quote.quote_summary
                    : order.order_items;
            items.forEach((item, index) => {
                const isRecurring =
                    item.price_data.model?.includes("recurring");
                if (isRecurring) {
                    const pricingSchedules = item.price_data?.price;
                    if (
                        !Boolean(
                            pricingSchedules[item.pricing_schedule]?.active
                        )
                    ) {
                        setValue(
                            `cart[${index}].pricing_schedule`,
                            Object.keys(pricingSchedules).filter(
                                (frequency) =>
                                    pricingSchedules[frequency].active
                            )[0],
                            { shouldDirty: true }
                        );
                    }
                }
            });
        }
    }, [currentStep, setValue, quote, order]);

    return (
        <FormProvider {...methods}>
            {currentStep === "build-quote" && (
                <EditQuote
                    account_id={account_id}
                    opportunity_id={opportunity_id}
                    isLinkedToCustomer={isLinkedToCustomer}
                    contact_id={contact_id}
                    account_primary_contact_id={account_primary_contact_id}
                />
            )}
            {currentStep === "add-products" && (
                <AddProducts
                    opportunity_id={opportunity_id}
                    account_id={account_id}
                    contact_id={contact_id}
                />
            )}
            {(currentStep === "order" || currentStep === "edit-order") && (
                <Order account_id={account_id} contact_id={contact_id} />
            )}
            {currentStep === "checkout" && <Payment account_id={account_id} />}
        </FormProvider>
    );
};

const QuoteBuilderModal = ({
    opportunity_id,
    account_id,
    contact_id,
    isLinkedToCustomer,
    account_primary_contact_id,
}) => {
    const {
        isReading,
        quote,
        quoteVersion,
        quoteVersions,
        isReadingQuoteVersions,
        quoteVersionError,
        orderError,
    } = useSelector((state) => ({
        isReading:
            state.quote.quoteBeingRead ||
            state.quote.quoteVersionBeingRead ||
            state.order.orderBeingRead,
        isReadingQuoteVersions: state.quote.quoteVersionsBeingRead,
        quote: state.quote.quote,
        quoteVersion: state.quote.quoteVersion,
        quoteVersions: state.quote.quoteVersions,
        quoteVersionError: state.quote.quoteVersionError,
        orderError: state.order.orderError,
    }));

    const { quoteBuilderConfig, onCloseModal, onViewProposal } =
        useContext(QuoteBuilderContext);

    const { currentStep, id, isUpsell } = quoteBuilderConfig || {
        currentStep: null,
        id: null,
        isUpsell: false,
    };

    const getModalHeader = () => {
        if (currentStep === "add-products") {
            return opportunity_id ? (
                "Add Products"
            ) : (
                <>
                    Product Catalog
                    {!isUpsell && (
                        <Popup
                            trigger={
                                <Icon
                                    size="small"
                                    style={{
                                        float: "right",
                                        marginRight: "1em",
                                        marginTop: "0.2em",
                                    }}
                                    className="fas fa-circle-info"
                                    color="primary"
                                />
                            }
                            content={
                                "Quotes can only be added to an opportunity"
                            }
                            inverted
                            position="bottom right"
                            size="mini"
                        />
                    )}
                </>
            );
        }
        if (currentStep === "build-quote") {
            return "Quote Builder";
        }
        if (currentStep === "order" || currentStep === "checkout") {
            return "Create Order";
        }
        if (currentStep === "edit-order") {
            return "Edit Order";
        }
        if (currentStep === "proposal-preview") {
            if (quoteVersionError) {
                return "Proposal";
            }
            return (
                <>
                    {quoteVersion?.quote_information?.name}
                    {!isReadingQuoteVersions && quoteVersions.length > 1 && (
                        <Dropdown
                            selection
                            options={quoteVersions.map((version) => ({
                                key: version.id,
                                value: version.id,
                                text: version.name,
                            }))}
                            value={id}
                            onChange={(_, { value }) => {
                                onViewProposal(value);
                            }}
                            selectOnBlur={false}
                            style={{ float: "right", marginRight: "1em" }}
                        />
                    )}
                </>
            );
        }
        return null;
    };

    const content = (() => {
        if (isReading || (currentStep === "build-quote" && !Boolean(quote))) {
            return (
                <Modal.Content>
                    <Segment basic loading />
                </Modal.Content>
            );
        }
        if (
            (currentStep === "proposal-preview" && quoteVersionError) ||
            (currentStep === "order" && orderError)
        ) {
            return (
                <Modal.Content>
                    <Error500 height="50vh" message={"Something went wrong"} />
                </Modal.Content>
            );
        }
        if (currentStep === "proposal-preview") {
            return <ProposalPreview opportunity_id={opportunity_id} />;
        }
        return (
            <ModalContent
                account_id={account_id}
                opportunity_id={opportunity_id}
                contact_id={contact_id}
                isLinkedToCustomer={isLinkedToCustomer}
                account_primary_contact_id={account_primary_contact_id}
            />
        );
    })();

    return (
        <Modal size="big" open={Boolean(currentStep)} onClose={onCloseModal}>
            <Modal.Header>{getModalHeader()}</Modal.Header>
            {content}
        </Modal>
    );
};

export default QuoteBuilderModal;
