import { DateTimeUtils } from "dyl-components";
import { getDefaultCartItems } from "shared/schemas/cart/cartSchema";
import { ObjectUtils } from "utils";

export const getQuoteDefaultValues = ({ quote, account_id }) => {
    const { street, city, state, zip } = quote.contact_info?.address || {};

    return {
        quote_status: quote.quote_status,
        cart: getDefaultCartItems(quote.quote_summary),
        name: quote.name,
        from_email: quote.from_information?.email,
        from_phone: quote.from_information?.phone,
        from_user_id: quote.from_information?.from_user_id,
        profile_info: quote.from_information?.profile_info,
        contact_email: quote.contact_info?.email,
        contact_phone: quote.contact_info?.phone,
        contact_id: quote.contact_info?.contact_id,
        contact_address:
            street && city && state && zip
                ? quote.contact_info?.address
                : {
                      address: {
                          additional_street: "",
                          city: "",
                          state: "",
                          street: "",
                          zip: "",
                      },
                      contact_id: account_id,
                      email: "",
                      phone: "",
                  },
        expires:
            quote.expires > 0
                ? DateTimeUtils.changeFormat(
                      DateTimeUtils.convertUnixTimeToDate(quote.expires, "UTC"),
                      "",
                      DateTimeUtils.WORD_DATE_FORMAT,
                      "UTC"
                  )
                : null,
        notes: quote.note,
    };
};

export const getOrderDefaultValues = ({ order, account_id }) => {
    const shippingAddress = order?.shipping_address?.location?.address;
    return {
        isShippingSameAsBilling:
            shippingAddress &&
            order?.shipping_address?.location?.contact_id ===
                order?.billing_address?.contact_id &&
            ObjectUtils.deepEqual(
                shippingAddress,
                order?.billing_address?.address
            ),
        order_status: order?.order_status || "",
        order_name: order?.name,
        billing_address: Object.keys(order?.billing_address || {}).length
            ? order?.billing_address
            : {
                  address: {
                      additional_street: "",
                      city: "",
                      state: "",
                      street: "",
                      zip: "",
                  },
                  contact_id: account_id,
                  email: "",
                  email_type: "",
                  phone: "",
                  phone_type: ""
              },
        shipping_address: Boolean(order?.shipping_address?.location?.address)
            ? {
                  address: order?.shipping_address?.location?.address,
                  phone: order?.shipping_address?.location?.phone,
                  phone_type: order?.shipping_address?.location?.phone_type,
                  email: order?.shipping_address?.location?.email,
                  email_type: order?.shipping_address?.location?.email_type,
                  ...(!Boolean(order?.shipping_address?.location?.contact_id)
                      ? (() => {
                            const [first_name, last_name, suffix] = (
                                order?.shipping_address?.ship_to || " _ _"
                            )?.split("_");
                            return {
                                contact_id: `shipping-contact`,
                                first_name,
                                last_name,
                                suffix,
                            };
                        })()
                      : {
                            ship_to: order?.shipping_address?.ship_to,
                            contact_id:
                                order?.shipping_address?.location?.contact_id,
                        }),
              }
            : {
                  address: {
                      additional_street: "",
                      city: "",
                      state: "",
                      street: "",
                      zip: "",
                  },
                  contact_id: account_id,
                  email: "",
                  phone: "",
              },
        cart: getDefaultCartItems(order.order_items),
    };
};
