import ACTION_NAMES from "actions/ACTION_NAMES";
import { generateCreateActionCreator, generateReadOneActionCreator, generateUpdateActionCreator } from "actions/crudActionCreators";
import routes from "actions/routes";

export default {
    read: generateReadOneActionCreator(`${routes.API_ORDER}/account`, ACTION_NAMES.ORDERS, 'orders'),
    readOrderInvoices: generateReadOneActionCreator(`${routes.API_ORDER}/order`, ACTION_NAMES.ORDER_INVOICES, 'invoice_summary'),
    create: generateCreateActionCreator(routes.API_ORDER, ACTION_NAMES.ORDER),
    readRecentOrder: generateReadOneActionCreator(`${routes.API_ORDER}/account`, ACTION_NAMES.RECENT_ORDER, 'order'),
    readOne: generateReadOneActionCreator(`${routes.API_ORDER}/account`, ACTION_NAMES.ORDER, 'order'),
    update: generateUpdateActionCreator(`${routes.API_ORDER}/account`, ACTION_NAMES.ORDER, () => {}, () => {}, 'order'),
    updateAddresses: generateUpdateActionCreator(`${routes.API_ORDER}/account`, ACTION_NAMES.ORDER_ADDRESSES, () => {}, () => {}, 'order')
}
